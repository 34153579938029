import React, { useState,useEffect} from "react";
import {Modal,Button} from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { GridCardContentSimilar } from "../grid/card/gridCardContentSimilar";
import { GridCardContentSimilarTheme2 } from "../grid/card/GridCardContentSimilarTheme2";
import { GridCardContentSimilarTheme3 } from "../grid/card/GridCardContentSimilarTheme3";
import {toAbsoluteUrl} from "../../../_helpers";
import axios from "axios";
import { BsArrowLeftShort } from "react-icons/bs";
import { FaExternalLinkAlt } from "react-icons/fa";
import VideoIframe from "../grid/card/VideoIframe";
import { NODE_API_URL } from "../../../../env_config";
import DetailSkeleton from "../../DetailSkeleton";
import { DetailBlock } from "../../detailComponents/DetailBlock";
import Cookies from "js-cookie";
import { SendInquiry } from "../../popup/sendInquiry";
const CancelToken = axios.CancelToken;
let cancel;
export const ProductDetailMainTheme3 = (props) => {
    const history = useHistory()
    const location = useLocation();
    const [data,setData] = useState(null)
    const [similarstones,setSimilarStones] = useState([])
    const [loader,setLoader] = useState(false)
    useEffect(() => {
        setLoader(true)
        const parentDomain = new URL(document.referrer || "http://localhost:3000/").origin
        const FinalObject = {
            StockID:location.state.StockID,
            diamond_type:location.state.diamond_type,
            user_id:localStorage.getItem("user_id"),
            token:localStorage.getItem("auth")
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/iframe/iFrameDiamondDetail`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`,
                "Parent-Domain":parentDomain
             },
            data: FinalObject,
        }).then(res => {
            setLoader(false)
            if(res && res.data && res.data.success){
                
        // Cancel the previous request if it exists
        if (cancel) {
            cancel();
          }
  
          // Create a new cancel token for the current request
          const newCancelToken = new CancelToken(function executor(c) {
            cancel = c;
          });
          let activity = {
            ...res.data.data,
            calculateprice:res.data.data.calculateprice.total_our_price,
            MARKUP_PRICE_PER_CTS:res.data.data.PRICE_PER_CTS
         }
         delete activity.PRICE_PER_CTS
          axios({
            method: "POST",
            url: `${NODE_API_URL}/iframe/storeUserACtivity`,
            headers: { 
                "Content-Type": "application/json",
                "Parent-Domain":parentDomain
            //    "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`
             },
            data: {
                user_id:FinalObject.user_id,
                activity_type:"IFrame Detail",
                user_agent:navigator.userAgent,
                referrer:parentDomain,
                activity_data:activity,
                ip_address:props.ipData.query,
                token:localStorage.getItem("auth")
            },
            cancelToken:newCancelToken
        }).then(response => {
        }).catch(error => {
        })
                setData(res.data.data)
        
        let newobj = {
            StockID:location.state.StockID,
            user_id:localStorage.getItem("user_id"),
            token:localStorage.getItem("auth"),
            shape:res.data.data.SHAPE,
            color:res.data.data.COLOR || "fancy",
            clarity:res.data.data.CLARITY,
            carat:res.data.data.CARAT,
            diamond_type:res.data.data.diamond_type
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/iframe/fetchSimilarDiamonds`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`,
                "Parent-Domain":parentDomain
             },
            data: newobj,
        }).then(simres => {
            if(simres && simres.data && simres.data.data && simres.data.data.length){
                setSimilarStones(simres.data.data)
            }
        })
            }
        })
    },[location.state])
    const [showVideo, setShowVideo] = React.useState(false);
    const [showAssetImg, setShowAssetImg] = React.useState(false);
    const [showArrowImg, setShowArrowImg] = React.useState(false);
    const [showHeartImg, setShowHeartImg] = React.useState(false);
 
    function handleClose(event, reason) {
        setShowVideo(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
    }

  
    const openVideoModal = () => setShowVideo(true);
    
    function openHeartModal() {
        setShowHeartImg(true)
    }
    function openAssetModal() {
        setShowAssetImg(true)
    }
    function openArrowModal() {
        setShowArrowImg(true)
    }

    function certificateLink(){
        if (data.LAB === "IGI"){
            const  certificate_link = `https://www.igi.org/viewpdf.php?r=${data.CERTIFICATE_NO}`
            return certificate_link
        } else if (data.LAB === "GIA"){
            const  certificate_link = `https://www.gia.edu/report-check?reportno=${data.CERTIFICATE_NO}`
            return certificate_link
        } else if (data.LAB === "HRD") {
            const certificate_link = `http://ws2.hrdantwerp.com/HRD.CertificateService.WebAPI/certificate?certificateNumber=${data.CERTIFICATE_NO}`
            return certificate_link
        } else if (data.LAB === "GCAL") {
            const certificate_link = `https://www.gcalusa.com/certificate-search.html?certificate_id=${data.CERTIFICATE_NO}`
            return certificate_link
        } else if (data.CERTIFICATE_LINK){
            const certificate_link = data.CERTIFICATE_LINK
            return certificate_link
        }
    }

    function Currency(){
        if (props.themeData && props.themeData.api_currency === "USD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "INR"){
            const  currencyIcon = "₹"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "HKD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "AUD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "EUR"){
            const  currencyIcon = "€"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "CAD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "CNY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "GBP"){
            const  currencyIcon = "£"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "NZD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "JPY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "CHF"){
            const  currencyIcon = "₣"
            return currencyIcon
        }  else if (props.themeData && props.themeData.api_currency === "SGD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "THB"){
            const  currencyIcon = "฿"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "MYR"){
            const  currencyIcon = "RM"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "BND"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "DKK"){
            const  currencyIcon = "kr."
            return currencyIcon
        } else if (props.themeData && props.themeData.api_currency === "SEK"){
            const  currencyIcon = "kr"
            return currencyIcon
        }
    }
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return (
        <>
            {loader?<DetailSkeleton/>:data?<div className={`container product_detail theme1 ${
                    data.diamond_type === "N" ? "natTab" : "labTab"
                }`}>
                <div className="card card-custom fiter-card card-sticky gutter-b">
                    <div className="card-body">
                        <div className="row">
                            {/* data: {JSON.stringify(data)} */}
                            <div className="col-12">
                                <span className="cursor-pointer text-hover-primary"  onClick={() =>{
                                    props.themeData && props.themeData.ifm_theme !== "theme1"?history.push("/searchdiamond",JSON.parse(sessionStorage.getItem("themesearch"))):history.goBack()
                                }}><BsArrowLeftShort /> {props.language.CCMODE_DIAMOND_DETAILS_GOTORESULT}</span>
                            </div>
                            <div className="col-md-6 d-flex flex-wrap flex-lg-nowrap">
                                <ul className="img_thumb_box mt-4 ml-5 pl-0 order-md-1 order-2">
                                    <li className="img_thumb">
                                        {data.IMAGE ? (
                                            <img
                                                className="img thumb"
                                                alt={data.SHAPE}
                                                src={data.IMAGE}
                                            />
                                        ) : (
                                            <img
                                                className="img thumb"
                                                alt="shape"
                                                
                                                src={toAbsoluteUrl(
                                                    `/media/shape/${data.SHAPE}-no.png`
                                                )}
                                            />
                                        )}
                                    </li>
                                    {data.VIDEO ? (
                                        <li className="img_thumb cursor-pointer" onClick={() => openVideoModal()}>
                                            <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-2.png" height="40px" width="40px"/>
                                            {/* <FaVideo className="font-size-sm text-primary cursor-pointer"  /> */}
                                            {/* <img  alt="img thumb" src={toAbsoluteUrl(`/media/shape/heart-no.png`)}/> */}
                                        </li>
                                    ):('')}
                                    {data.AWS_Heart ? (
                                        <li className="img_thumb cursor-pointer" onClick={() => openHeartModal()}>
                                            <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-5.png"/>
                                           
                                        </li>
                                        
                                    ):('')}
                                    {data.AWS_Arrow ? (
                                        <li className="img_thumb cursor-pointer" onClick={() => openArrowModal()}>
                                            <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-6.png"/>
                                            
                                        </li>
                                    ):('')}
                                    {data.AWS_Asset ? (
                                        <li className="img_thumb cursor-pointer" onClick={() => openAssetModal()}>
                                            <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-3.png"/>
                                            
                                        </li>
                                    ):('')}
                                </ul>
                                <div className="img_box mt-4 order-md-2 order-1">
                                    {data.IMAGE ? (
                                        <img
                                            className="img-fluid"
                                            alt={data.SHAPE}
                                            src={data.IMAGE}
                                        />
                                    ) : (
                                        <img
                                            className="img-fluid"
                                            alt="shape"
                                            src={toAbsoluteUrl(
                                                `/media/shape/${data.SHAPE}-no.png`
                                            )}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-12 my-3 d-flex justify-content-lg-start align-items-center">
                                        <span className="font-size-sm text-primary mr-4">
                                            <a
                                                id={data.CERTIFICATE_NO}
                                                href={certificateLink()}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="font-size-sm text-primary"
                                            >
                                                {data.LAB}-{data.CERTIFICATE_NO} <FaExternalLinkAlt className="ml-1"/>
                                            </a>
                                        </span>
                                        <div className="font-size-sm mr-1 text-dark-50">Stock No: <div className="font-size-sm text-dark-50 font-weight-bold d-inline-block">{data.diamond_type === "N" ? "N" :"L"}-{data.STOCK_ID ? data.STOCK_ID : "-"}</div></div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <span className="text-dark font-weight-bold text-capitalize font-size-h2">
                                        <span className="pr-2">{data.LAB}</span>
                                            <span className="pr-2">
                                                {data.CARAT && parseFloat(data.CARAT).toFixed(2)}
                                            </span>
                                            <span className="pr-2">{data.SHAPE}</span>
                                            { (data.FANCY_COLOR) ?
                                                (
                                                    <>
                                                        <span className="pr-2">{data.FANCY_INTENSITY}</span>
                                                        <span className="pr-2">{data.FANCY_OVERTONE}</span>
                                                        <span className="pr-2">{data.FANCY_COLOR}</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="pr-2">{data.COLOR}</span>
                                                    </>
                                                )
                                            }
                                            <span className="pr-2">{data.CLARITY}</span>
                                            <span className="pr-2">{data.CUT}</span>
                                            <span className="pr-2">{data.POLISH}</span>
                                            <span className="pr-2">{data.SYMMETRY}</span>
                                            <span className="pr-2">{data.FLUORESCENCE}</span>
                                        </span>
                                    </div>
                                    {props.themeData && !props.themeData.ifm_no_price?<div className="col-12 d-flex align-items-end mt-5">
                                        <span>
                                            <div className="font-size-sm text-dark-50">{Currency()}/CT {data.PRICE_PER_CTS ? numberWithCommas(data.PRICE_PER_CTS):null}</div>
                                            <div className="font-size-h1 font-weight-bold text-primary">{Currency()}{data.PRICE_PER_CTS ? numberWithCommas(data.TOTAL_PRICE):null}</div>
                                        </span>
                                    </div>:""}
                                   <div className="col-12 mt-3">
                                        <SendInquiry diamond_details={data} ipData={location.state.ipData ? location.state.ipData :props.ipData} language={props.language}/>
                                   </div>
                                    <div className="col-12 mt-5">
                                        <span className="d-flex align-items-center font-weight-bold">
                                            <div className="text-dark mr-1">M</div>
                                            <div className="text-primary ml-2">  {data.LENGTH ? parseFloat(data.LENGTH).toFixed(2) : null} x {data.WIDTH ? parseFloat(data.WIDTH).toFixed(2) : null} x {data.DEPTH ? parseFloat(data.DEPTH).toFixed(2) : null}  </div>
                                        </span>
                                    </div>
                                    <div className="col-12">
                                        <span className="d-flex align-items-center font-weight-bold">
                                            <div className="text-dark mr-1">T</div>
                                            <div className="text-primary ml-2">  {data.TABLE_PER ? <>{parseFloat(data.TABLE_PER).toFixed(2)}%</> : "-"}  </div>
                                        </span>
                                    </div>
                                    <div className="col-12">
                                        <span className="d-flex align-items-center font-weight-bold">
                                            <div className="text-dark mr-1">D</div>
                                            <div className="text-primary ml-2">  {data.DEPTH_PER ? <>{parseFloat(data.DEPTH_PER).toFixed(2)}%</> : "-"}  </div>
                                        </span>
                                    </div>
                                    {data.RATIO ? (
                                        <div className="col-12">
                                            <span className="d-flex align-items-center font-weight-bold">
                                                <div className="text-dark mr-1">R</div>
                                                <div className="text-primary ml-2">  {data.RATIO ? <>{data.RATIO}</> : "-"}  </div>
                                            </span>
                                        </div>
                                    ):('')}
                                    {/* <div className="col-12">
                                        <span className="d-flex align-items-center font-weight-bold">
                                            <span className="text-dark mr-1"><FaCalendarAlt className="text-danger mr-2" /></span> {props.language.CCMODE_DIAMOND_DETAILS_SHIPPINGDAYS}:
                                            <span className="text-primary ml-2">{data.Shipping_Days ? data.Shipping_Days : "-"} {props.language.CCMODE_THEME1__LIST_DAYS}</span>
                                        </span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-custom fiter-card card-sticky gutter-b">
                    <div className="card-header justify-content-center mb-3">
                        <div className="card-title m-0">
                            <span className="card-label text-dark">
                                {props.language.CCMODE_DIAMOND_DETAILS_DESCRIPTION}
                            </span>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            {/* data: {JSON.stringify(data)} */}
                            <div className="col-md-6">
                                <div className="font-size-h5 text-dark font-weight-bolder mb-3 text-primary"> {props.language.CCMODE_DIAMOND_DETAILS_DIAMONDDETAIL} </div>
                                {/* <div className="row mb-1">
                                    <div className="col-6"> Availability </div>
                                    <div className="col-6 font-weight-bolder"> {data.Availability ? data.Availability : "-"} </div>
                                </div> */}
                                <div className="row mb-1">
                                    <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_STOCKID} </div>
                                    <div className="col-6 font-weight-bolder"> {data.diamond_type === "N" ? "N" :"L"}-{data.STOCK_ID ? data.STOCK_ID : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_SHAPE} </div>
                                    <div className="col-6 font-weight-bolder">  {data.SHAPE ? data.SHAPE : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_CARAT} </div>
                                    <div className="col-6 font-weight-bolder">  {data.CARAT ? data.CARAT : "-"} </div>
                                </div>
                                { (data.COLOR !=="fancy") ? (
                                    <div className="row mb-1">
                                        <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_COLOR} </div>
                                        <div className="col-6 font-weight-bolder">  {data.COLOR ? data.COLOR : "-"} </div>
                                    </div>
                                ):('')}
                               
                                <div className="row mb-1">
                                    <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_CLARITY} </div>
                                    <div className="col-6 font-weight-bolder">  {data.CLARITY ? data.CLARITY : "-"} </div>
                                </div>
                                { (data.CUT) ? (
                                    <div className="row mb-1">
                                        <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_CUT} </div>
                                        <div className="col-6 font-weight-bolder">  {data.CUT ? data.CUT : "-"} </div>
                                    </div>
                                ):('')}
                                <div className="row mb-1">
                                    <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_POLISH} </div>
                                    <div className="col-6 font-weight-bolder">  {data.POLISH ? data.POLISH : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_SYMMETRY} </div>
                                    <div className="col-6 font-weight-bolder">  {data.SYMMETRY ? data.SYMMETRY : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_FLOUR}</div>
                                    <div className="col-6 font-weight-bolder">  {data.FLUORESCENCE ? data.FLUORESCENCE : "-"} </div>
                                </div>
                                {/* <div className="row mb-1">
                                    <div className="col-6"> Fluorescence Intensity </div>
                                    <div className="col-6 font-weight-bolder">  {data.F_Intensity ? data.F_Intensity : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> Fluorescence Color </div>
                                    <div className="col-6 font-weight-bolder">  {data.F_Color ? data.F_Color : "-"} </div>
                                </div> */}
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_LAB}</div>
                                    <div className="col-6 font-weight-bolder">  {data.LAB ? data.LAB : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_CERTIFICATE}</div>
                                    <div className="col-6 font-weight-bolder">
                                        <a
                                            id={data.CERTIFICATE_NO}
                                            href={certificateLink()}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="font-size-sm text-primary"
                                        >
                                            {data.CERTIFICATE_NO} <FaExternalLinkAlt className="ml-1"/>
                                        </a>
                                    </div>
                                </div>
                                {/* <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_LOCATION}</div>
                                    <div className="col-6 font-weight-bolder">  {data.COUNTRY ? data.COUNTRY : "-"} </div>
                                </div> */}
                            </div>
                            <div className="col-md-6"> 
                                { (data.FANCY_COLOR) ? (
                                    <>
                                        <div className="font-size-h5 text-dark font-weight-bolder mb-3 text-primary">Fancy Color Details </div>
                                        <div className="row mb-1">
                                            <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_INTENSITY}</div>
                                            <div className="col-6 font-weight-bolder">  {data.FANCY_INTENSITY ? data.FANCY_INTENSITY : "-"} </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_OVERTONE}</div>
                                            <div className="col-6 font-weight-bolder">  {data.FANCY_OVERTONE ? data.FANCY_OVERTONE : "-"} </div>
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_FANCYCOLOR}</div>
                                            <div className="col-6 font-weight-bolder">  {data.FANCY_COLOR ? data.FANCY_COLOR : "-"} </div>
                                        </div>
                                    </>
                                ):('')}
                               
                                <div className="font-size-h5 text-dark font-weight-bolder mb-3 text-primary"> {props.language.CCMODE_DIAMOND_DETAILS_PARAMDETAILS} </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_MEASUREMENT}</div>
                                    <div className="col-6 font-weight-bolder">  {data.LENGTH} x {data.WIDTH} x {data.DEPTH}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_TABLE} %</div>
                                    <div className="col-6 font-weight-bolder">  {data.TABLE_PER ? `${data.TABLE_PER}%` : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_DEPTH} %</div>
                                    <div className="col-6 font-weight-bolder">  {data.DEPTH_PER ? `${data.DEPTH_PER}%` : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_CROWN}</div>
                                    <div className="col-6 font-weight-bolder">  {data.CROWNHEIGHT ? `${data.CROWNHEIGHT}%` : "-"}, {data.Crn_Ag ? `${data.Crn_Ag}°` : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_CROWNPAVILION}</div>
                                    <div className="col-6 font-weight-bolder">  {data.PAVILIONHEIGHT ? `${data.PAVILIONHEIGHT}%` : "-"}, {data.Pav_Ag ? `${data.Pav_Ag}°` : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_GIRDLE}</div>
                                    <div className="col-6 font-weight-bolder">   {data.GIRDLE ? data.GIRDLE : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_GIRDLE_THICK}</div>
                                    <div className="col-6 font-weight-bolder">   {data.GIRDLE_CONDITION ? data.GIRDLE_CONDITION : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_GIRDLE_THIN}</div>
                                    <div className="col-6 font-weight-bolder">   {data.girdle_thin ? data.girdle_thin : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_GIRDLE_CUTLET}</div>
                                    <div className="col-6 font-weight-bolder">   {data.CULET ? data.CULET : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_SHADE}</div>
                                    <div className="col-6 font-weight-bolder">{data.SHADE ? data.SHADE : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_MILKY}</div>
                                    <div className="col-6 font-weight-bolder">{data.MILKY ? data.MILKY : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_EYECLEAN}</div>
                                    <div className="col-6 font-weight-bolder">{data.EYE_CLEAN ? data.EYE_CLEAN : "-"}  </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{props.language.CCMODE_DIAMOND_DETAILS_KEY_TO_SYMBOL}</div>
                                    <div className="col-6 font-weight-bolder">{data.KEY_TO_SYMBOL ? data.KEY_TO_SYMBOL : "-"}  </div>
                                </div>
                                {data.diamond_type === "L" ? 
                                    <>
                                        { (data.Growth_Type) ? (
                                            <div className="row mb-1">
                                                <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_GROWTHTYPE} </div>
                                                <div className="col-6 font-weight-bolder">  {data.Growth_Type ? data.Growth_Type : "-"} </div>
                                            </div>
                                        ):('')}
                                        { (data.TREATMENT) ? (
                                            <div className="row mb-1">
                                                <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_TREATMENT} </div>
                                                <div className="col-6 font-weight-bolder">  {data.TREATMENT ? data.TREATMENT : "-"} </div>
                                            </div>
                                        ):('')}
                                    </>
                                :      
                                    <>
                                        { (data.ORIGIN) ? (
                                            <div className="row mb-1">
                                                <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_ORIGIN} </div>
                                                <div className="col-6 font-weight-bolder">  {data.ORIGIN ? data.ORIGIN : "-"} </div>
                                            </div>
                                        ):('')}
                                        { (data.BRAND) ? (
                                            <div className="row mb-1">
                                                <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_BRAND} </div>
                                                <div className="col-6 font-weight-bolder">  {data.BRAND ? data.BRAND : "-"} </div>
                                            </div>
                                        ):('')}
                                         { (data.TREATMENT) ? (
                                            <div className="row mb-1">
                                                <div className="col-6"> {props.language.CCMODE_DIAMOND_DETAILS_TREATMENT} </div>
                                                <div className="col-6 font-weight-bolder">  {data.TREATMENT ? data.TREATMENT : "-"} </div>
                                            </div>
                                        ):('')}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-custom fiter-card card-sticky gutter-b">
                    <div className="card-body">
                        <DetailBlock color={data.COLOR} carat={data.CARAT} clarity={data.CLARITY} cut={data.CUT} shape={data.SHAPE} intensity={data.FANCY_INTENSITY} fancycolor={data.FANCY_COLOR}/>
                    </div>
                </div>
                <div className="card card-custom fiter-card card-sticky gutter-b">
                    <div className="card-header justify-content-center mb-3">
                        <div className="card-title m-0">
                            <span className="card-label text-dark">
                                {props.language.CCMODE_DIAMOND_DETAILS_SIMILAR_DIAMONDS}
                            </span>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row m-0">
                            {similarstones.length ?  
                                similarstones.map((val) => {
                                    return (
                                        <>
                                            { props.themeData.ifm_theme === "theme1" ?
                                                <GridCardContentSimilar
                                                    key={val.ProductId}
                                                    {...val}
                                                    language={props.language}
                                                    themeData={props.themeData}
                                                    ipData={props.ipData}
                                                /> : null
                                            }
                                            { props.themeData.ifm_theme === "theme2" ?
                                                <GridCardContentSimilarTheme2 
                                                    key={val.ProductId}
                                                    {...val}
                                                    language={props.language}
                                                    themeData={props.themeData}
                                                    ipData={props.ipData}
                                                /> : null
                                            }
                                            { props.themeData.ifm_theme === "theme3" ?
                                                <GridCardContentSimilarTheme3 
                                                    key={val.ProductId}
                                                    {...val}
                                                    language={props.language}
                                                    themeData={props.themeData}
                                                    ipData={props.ipData}
                                                /> : null
                                            }
                                        </>
                                    );
                                })
                             : <div className="col-12 text-center">
                                <p className="font-size-h5"> No records found</p>
                             </div> }
                        </div>
                    </div>
                </div>
                
                <VideoIframe
                    showVideo={showVideo}
                    handleClose={handleClose}
                    src={data.diamond_type === "N" ? `https://pro360video.com/video.php?refno=${data.CERTIFICATE_NO}` : `https://pro360video.com/labgrown.php?refno=${data.CERTIFICATE_NO}` }
                />
                <Modal
                    show={showHeartImg}
                    onHide={handleClose}
                    size="lg"
                >
                    <Modal.Body className="text-center">
                        <img src={data.AWS_Heart} alt={data.SHAPE} className="img-fluid" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showArrowImg}
                    onHide={handleClose}
                    size="lg"
                >
                    <Modal.Body className="text-center">
                        <img src={data.AWS_Arrow} alt={data.SHAPE} className="img-fluid" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showAssetImg}
                    onHide={handleClose}
                    size="lg"
                >
                    <Modal.Body className="text-center">
                        <img src={data.AWS_Asset} alt={data.SHAPE} className="img-fluid" />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                        >
                        Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>:<div className="card custom-card gutter-b">
                <div className="card-body d-flex align-items-center  justify-content-center" style={{height:'70vh'}}>
                    <div className="text-center">
                        <img src={toAbsoluteUrl("/media/General/searchresult-no.png")} className="img-fluid" alt="searchresult-no"/>
                        <div className="mt-8 font-size-h4 text-capitalize text-primary"><strong>No Diamonds Found</strong></div>
                        <button className="btn btn-primary mt-5 px-8" onClick={() => history.push("/searchdiamond")}>Back To Search</button>
                        {/* <Link className="btn btn-primary px-8 mt-5" to={{ pathname:'/searchdiamond/white', option: "white" }}>Search Diamonds</Link> */}
                    </div>
                </div>
            </div>}
        </>
    );
};
